export default [
  'Filling the ocean....',
  'Drinking fish juice...',
  'Scrubbing the whale shark...',
  'Selling sea shells by the sea shore...',
  'Painting corals...',
  'Petting horn shark...',
  'Casting stingrays...',
  'Blowing up pufferfish...',
  'Combing hairy frogfish...',
  'Slaying sea dragons...',
  'Educating dumbo octopus...',
  'Landing scotoplanes...',
  'Sonically charging mantis shrimp...',
  'Attaching components to sea turtles...',
  'Hiding red components from bull shark...',
  'Gathering grass for cownose ray...',
  'Painting white stripes on zebra shark...',
  'Painting green turtles red',
  'Gathering giant nuts for coconut octopus...',
  'Polishing crown for red king crab...',
  'Hiding garlic from vampire squid...',
  'Decotor crab is decorating text, please wait...',
  'Chasing away squirrels from acorn barnacles...',
  'Getting cushion stars away from the couch...',
  'Peeling edible sea cucumbers...',
  'Draning water with yellow tube sponge...',
  'Training portugese man o\' war',
  'Running away from sea wasp...',
  'Fabricating hoaxes about moon jelly',
  'Seasoning fried egg jellyfish with salt and pepper...',
  'Admiring eccentric sand dollars...',
  'Corking up bluebottles...',
  'Thinking with grooved brain corals...',
  'Tuning harp seals...',
  'Learning from my octopus teacher...',
  'Making jokes with the clownfish',
  'Performing surgery with beluga sturgeon...',
  'Doing 360 noscope with slender snipe eel...',
  'Doing stand-up routines with sarcastic fridgehead...',
  'Filling up treasure chests...',
  'Sharpening crab claws...',
  'Feeding fish...',
  'Finding Nemo...',
  'Removing unused tentacles...',
]
