<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col>
        <octo-patreon />
      </v-col>
      <v-col>
        <octo-buy-us-coffee />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style>

</style>
